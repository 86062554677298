import React from 'react';
import { PathService } from '../../services';
import { ServicesListViewContext, TemplateProps } from '../../types';
import { ServicesListView } from '../../views';

const ServicesList: React.FunctionComponent<
  TemplateProps<ServicesListViewContext>
> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <ServicesListView
      list={JSON.parse(pageContext.list)}
      page_group={JSON.parse(pageContext.page_group)}
    />
  );
};

export default ServicesList;
